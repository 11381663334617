import React from 'react';
import './About.css';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';

export default function About() {
    return (
        <div id="_AboutSection" className="About">

            <div className="AboutBar" />
            <div className="AboutImage">
                <img
                    src="/images/about.jpg"
                    alt="Picture of the author"
                />
                <div className="AboutImageLogo">
                    <img
                        src="/images/logo.png"
                        alt="Picture of the author"
                    />
                </div>
            </div>
            <div className="AboutContent">
                <div className="AboutContentTitle">
                    <h4>ABOUT ME</h4>
                    <h2>NEHA SAINI GUPTA</h2>
                    <div className="AboutContentTitleBar"></div>
                </div>
                <div className="AboutContentText">
                    <p>
                    Neha Saini Gupta is a Professional Makeup Artist who is creative, passionate, and charismatic about her work and has transformed the look of a number of women & men for different occasions & events. Started in 2017, Neha makeovers aspires to create a world of beautiful and confident women by adding her miracle touch of makeup.
                    </p>
                    <p>
                    Being an Engineer by profession, Neha followed her passion heading towards her journey of becoming a renowned artist in the makeup industry. After completing her training from a renowned fashion Academy, Neha started her own venture and is currently serving her large client base in Dehradun. Her specialization includes fashion, bridal, groom, creative, and editorial makeup techniques. She employs her extensive knowledge and impeccable skills to craft tailor-made beauty and hair makeovers that grabs everyone’s attention.
                    </p>
                    <p>
                    As per Neha, “There is fashion in every street of India. A good makeup adds a sense of confidence in you.”
                    </p>
                    <p>“Anyone can add colour to a face but knowing “How to” and “When to” takes a skillful and professional eye”, she further added.</p>

                    <p>Based in Dehradun, Neha’s passion for makeup has made her brand- Neha Makeovers one of the most sought after makeover specialists in the city. From a decade, she has been dealing & trained with top brands like- Maybelline, Bobby Brown, MAC, Lakme etc.  Till now, she has created countless sensational looks that have earned her unparalleled goodwill.</p>
                    <p>Today, Neha has grown to become a professional name to be reckoned with in the fields of Hair and Makeup. From wedding functions to fashion events, Neha and her team have been serving thousands of clients in & across Dehradun.</p>
                </div>
                <div className="IconsContainer">
                    <a href="#_ContactSection">
                        <div className="AboutContentBtn">
                            CONTACT ME
                        </div>
                    </a>
                    <div className="Socials">
                        <a href="https://www.facebook.com/glamstoriesbyneha" target="_blank"><FaFacebookF size="2.25rem" color="inherit" /></a>
                        <a href="https://www.instagram.com/nehamakeoversofficial" target="_blank"><FaInstagram size="2.25rem" color="inherit" /></a>
                        <a href="https://www.youtube.com/channel/UC5AisCyMSTLC2GjOMGj6vJg" target="_blank"><FaYoutube size="2.25rem" color="inherit" /></a>
                    </div>
                </div>

            </div>
        </div>
    )
}
