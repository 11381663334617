import React from 'react';
import './NavBar.css';
import { motion } from "framer-motion";

export default function NavBar() {
    return (
        <motion.div
            className="Nav"
            exit={{ x: -100, opacity: 0 }}
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
        >
            <div className="NavLogo">
                <img
                    src="/images/logo.png"
                    alt="Pic"
                />
            </div>
            <div className="NavContent">
                <div>HOME</div>
                <div><a href="#_AboutSection">ABOUT</a></div>
                <div><a href="#_PortfolioSection">PORTFOLIO</a></div>
                <div><a href="#_TestimonialsSection">TESTIMONIALS</a></div>
                <div><a href="#_ContactSection">CONTACT</a></div>
            </div>

            <div className="NavFooter">
                <div className="NavFooterBar" />
                <div className="NavFooterPhone">
                    +91 (959) 990 2081
                </div>
                <div className="NavFooterEmail">
                    nehamakeoversofficial@gmail.com
                </div>
            </div>
        </motion.div>
    )
}
