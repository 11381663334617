import React from 'react';
import HomePage from './HomePage';
import ImagePage from './ImagePage';
import { Switch, Route } from 'react-router-dom';

export default function App() {
    return (
        <div>
            <Switch>

                <Route path="/" exact>
                    <HomePage />
                </Route>
                <Route path="/:id" exact>
                    <ImagePage />
                </Route>

            </Switch>
            
        </div>
    )
}
