import React, { useState, useEffect, useRef } from 'react';
import './ImagePage.css';

import Img1 from '../../portfolio/img.JPG';
import Img2 from '../../portfolio/img1.jpg';
import Img3 from '../../portfolio/img2.jpg';
import Img4 from '../../portfolio/img3.jpg';
import Img5 from '../../portfolio/img4.jpg';
import Img6 from '../../portfolio/img5.JPG';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { BiRightArrowAlt } from 'react-icons/bi';
import Footer from '../Footer/Footer';
import { CgClose } from 'react-icons/cg';
import { GiFeatheredWing } from 'react-icons/gi';
import { useParams, Link } from 'react-router-dom';

export default function ImagePage() {
    let { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let data = [
        {
            id: 1,
            imgSrc: Img1
        },
        {
            id: 2,
            imgSrc: Img2
        },
        {
            id: 3,
            imgSrc: Img3
        },
        {
            id: 4,
            imgSrc: Img4
        },
        {
            id: 5,
            imgSrc: Img5
        },
        {
            id: 6,
            imgSrc: Img6
        },
    ]

    const [model, setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState('');
    const [tempImgIndex, setTempImgIndex] = useState();

    const getImg = (imgSrc, index) => {
        setTempImgSrc(imgSrc);
        setModel(true);
        setTempImgIndex(index);
    }
    const closeImg = () => {
        setTempImgSrc('');
        setModel(false);
        setTempImgIndex();
    }

    const goLeft = () => {
        let index;
        if (tempImgIndex === 0) {
            index = data.length - 1;
            setTempImgIndex(index);
            setTempImgSrc(data[index]['imgSrc']);
        } else {
            index = tempImgIndex - 1
            setTempImgIndex(index);
            setTempImgSrc(data[index]['imgSrc']);
        }
    }

    const goRight = () => {
        let index;
        if (tempImgIndex === data.length - 1) {
            index = 0;
            setTempImgIndex(index);
            setTempImgSrc(data[index]['imgSrc']);
        } else {
            index = tempImgIndex + 1
            setTempImgIndex(index);
            setTempImgSrc(data[index]['imgSrc']);
        }
    }

    const keyboardRight = () => {
        if (model) {
            goRight();
        }
    }

    const keyboardLeft = () => {
        if (model) {
            goLeft();
        }
    }

    useKey("ArrowLeft", keyboardLeft);
    useKey("ArrowRight", keyboardRight);

    return (
        <div className="_PortfolioPage">
            <Link to="/">
                <div className="_PortfolioPageLogo">
                    <img
                        src="/images/logo.png"
                        alt="Picture of the author"
                    />
                </div>
            </Link>
            <div className="_PortfolioPageHeader"><GiFeatheredWing size="4rem" color="inherit" />{id} <span><GiFeatheredWing size="4rem" color="inherit" /></span></div>
            <div className="PortfolioExplainer">

                {
                    id === "Bridal Makeup" && (
                        <div>
                            “On your wedding day, you should look like yourself at your most beautiful.” - Bobbi Brown.
                            Every bride wants to look stunning on their wedding day. For a bride planning her dream wedding, every little attention to detail carries a great importance.
                            Scouting for the best Bridal Makeup Artist near you?  You have come to the right place!
                            Neha is a Dehradun based makeup artist who loves to transform her brides into a beautiful princess. Neha Makeovers understands the expectations of brides & offer them exactly the same what they dreamt of. From Subtle undertones to bold eyes, nude lip shade to sparkling bright lip color that grabs everyone’s attention, Neha understands what the ‘bride to be’ wants on her special day and accordingly customizes the makeup so that her every bride looks a class apart.
                            Neha Makeovers offers the best in Bridal Makeup, Party, Occasion, Event & Media, Editorial and Fashion Makeup in Normal, HD and Airbrush. Our services include all kinds of beauty packages like Bridal & Pre-bridal Makeup Package, Reception Makeup Package, Engagement Makeup Package, Party Makeup Package, and Mehandi & Cocktail Makeup Package, etc. These services are available in our studio in Dehradun and also available freelance at the venue all over India.
                        </div>
                    )
                }
                {
                    id === "Mehendi" && (
                        <div>
                            Be all set for Mehendi Glam with some glitters and gold to rock your Mehendi stage!
                            It’s a function of colors and “Rang Roop” so why not invest your look with quirky and playful makeup
                            appeal. We at Neha Makeovers, offers you all kind of sassy looks to choose from for your Mehendi Day.
                            We will let you nail your mehndi makeup look with a flawless base, luscious lips, and a pop of color to
                            make all eyes go on you. We will work with you to create your own customized look whether it’s natural,
                            glamorous, sexy, or classic!
                        </div>
                    )
                }

                {
                    id === "Pre-Bridal Makeup" && (
                        <div>
                            Offering pre-bridal services at it’s best for a bride to be all set with glam and glitter!
                            At Neha Makeovers, we are dedicated and determined to make a bride feel all special on her big day
                            with complete support and source for diva look. Together, we sit back and discuss the looks for all
                            occasions, accessories that go along with, makeup type and look; to almost everything that puts you up
                            in a space of relaxation. Herein, we personally gel with the bride to support their makeup looks, for an
                            instance let’s talk about engagement look- from the outfit as per your body shape to counseling of
                            makeup look, to jewellery and also the store suggestions to shop from. We are all there with you
                            throughout your beautiful journey of Wedding Day!
                        </div>
                    )
                }

                {
                    id === "Party Makeup" && (
                        <div>
                            Wondering out what’s the best for a party makeup.
                            Well it can go anything from nude to glamorous, to Smokey to colored. We at Neha Makeovers, helps in
                            curating your party looks as per your dress, theme line of the event/party and let one choose as per
                            their requirement. From mother, to daughter, to a farewell attending girl, or to any social event; we help
                            you with all kind of Party makeup looks. We provide professional and unique party makeup services
                            using high quality beauty products. No matter what your event is, we will help you obtain to your
                            desired look. We specialize in both Indian and western style makeup.
                        </div>
                    )
                }

                {
                    id === "Senior Makeup Artist" && (
                        <div>
                            These Makeup Artists specializes in the art of makeup.
                            At Neha Makeovers, we give you choices to choose your own makeup artist as per your event
                            requirement. For you and your bridesmaids or for any other occasion, we have a pro team of senior
                            makeup artists and stylists that holds a portfolio of expertise. This service is exclusively offered on
                            request by a client. Available for Bridal Makeup, Party Makeup, Occasion Makeup, Sagan, Sangeet,
                            Mehendi, Cocktails and any function makeup. They are certified makeup artist and offer on-on one

                            service. Our service quality is differentiated from others in terms of experienced makeup artists and high
                            quality cosmetics. We aim at providing perfect and unparalleled service with senior makeup-artists.
                        </div>
                    )
                }

                {
                    id === "Hairstyling" && (
                        <div>
                            Woman’s hair is a magnificent beauty! 
                            Every woman wants a New Hair Style, especially on an occasion. And the hassle of hairstyling along with
                            makeup is fixed at Neha Makeovers. From different styles to choose from to different accessories to add
                            on your hair, everything is sorted here. Depending on the occasion, theme of party and the kind of dress
                            you are wearing, we select the most suitable hair style for you that will accentuate your look. Our
                            hairstylists and hair dressers are well qualified and works as per the face and makeup look. After all,
                            hairstyling is considered to be the crown of a lady.
                        </div>
                    )
                }

                {
                    id === "Fashion & Beauty" && (
                        <div>
                            Nailing the high fashion makeup styles and looks like theatre and cinematic
                            with a modern phenomenon!
                            If high street fashion makeup is your preferred genre of glam, look no further than Neha Makeovers.
                            With us you can add a Hint of Glamour to Your Looks with Our Range of Upstyles of fashion &amp;
                            makeup service. From unicorn theme makeup look to other prosthetic based, we are sure to put you
                            in light with gorgeous makeup art that actually defines the work of an artist like us. Scroll down
                            below pictures and choose your look, or else we are here to create a masterpiece out of your
                            inspiration and look appeal.
                        </div>
                    )
                }
                
                </div>

                <Gallery data={data} model={model} tempImgSrc={tempImgSrc} tempImgIndex={tempImgIndex} getImg={getImg} closeImg={closeImg} goLeft={goLeft} goRight={goRight} />
        
            <h1 className="_PortfolioPageFooter">All Rights Reserved, 2021</h1>
        </div>
    )
}


const Gallery = ({ data, model, tempImgSrc, tempImgIndex, getImg, closeImg, goLeft, goRight }) => {



    return (
        <>
            <div className={model ? "model open" : "model"}>
                <img src={tempImgSrc} alt="All New Image Model" />
                <div className="Controls">
                    <div onClick={goLeft}>
                        <BiLeftArrowAlt size="2rem" color="inherit" />
                    </div>
                    <div onClick={goRight}>
                        <BiRightArrowAlt size="2rem" color="inherit" />
                    </div>
                </div>
                <div onClick={() => closeImg()} id="ClosePortfolioIcon"><CgClose size="3rem" color="#fff" /></div>
            </div>
            <div className="gallery">
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className="pics" onClick={() => getImg(item.imgSrc, index)}>
                                <img src={item.imgSrc} style={{ width: '100%' }} />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}


function useKey(key, cb) {

    const callbackRef = useRef(cb);

    useEffect(() => {
        callbackRef.current = cb;
    });

    useEffect(() => {

        function handle(event) {
            if (event.code === key) {
                callbackRef.current(event);
            }
        }
        // use 'keypress' incase of something like 'enter'
        document.addEventListener('keydown', handle)
        return () => document.addEventListener('keydown', handle)
    }, [key]);

}