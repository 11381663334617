import React from 'react';
import './Video.css';
import ReactPlayer from 'react-player';
import { GrFormClose } from "react-icons/gr";

export default function Video({handleVideo}) {
    return (
        <div className="Video">
            <ReactPlayer
                url='https://www.youtube.com/watch?v=yw8VNVDOwK8'
                controls={true}
                //  light={true}
                className="VideoPlayer"
            />
            <div onClick={handleVideo} className="VideoClose">
                <GrFormClose size="2.75rem" color="#fff" />
            </div>
        </div>
    )
}
