import React from 'react';
import './Contact.css';
import Form from './Form';
import Map from './Map';

export default function Contact() {
    return (
        <>
            <div id="_ContactSection" className="Contact">
                <div className="ContactHeader">
                    <h6>HOW TO CONTACT US</h6>
                    <h2>SEND AN EMAIL</h2>
                    <div className="ContactHeaderBar"></div>
                </div>
                <div className="ContactContent">
                    <Form />
                    <Map />
                </div>
            </div>
        </>
    )
}
