import React from 'react';
import './Contact.css';
import { BsArrow90DegRight } from "react-icons/bs";

export default function Map() {

    return (
        <div className="Map">
            <div className="MapScreen" />
            <a href="https://www.google.com/maps/dir/26.1352637,91.8015192/Neha+Makeovers+dehradun/@27.9235173,75.6281264,5z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x390929f05e85e3e3:0xf5c534e8ba226d08!2m2!1d78.0304842!2d30.3266152">
            <button><BsArrow90DegRight size="1.6rem" color="#fff"/>Get Directions</button>
            </a>
        </div>
    )
}