import React from 'react';
import './Footer.css';

export default function Footer() {
    return (
        <div className="Footer">
        <div className="FooterContent">
            <div>
                <img
                    src="/images/logo.png"
                    alt="Picture of the author"
                />
            </div>
            <div>
                Neha Makeovers, All Rights Reserved 2021
            </div>
        </div>
    </div>
    )
}
