import React, {useState} from 'react';
import './Testimonials.css';

import { ImQuotesLeft } from "react-icons/im";

export default function Testimonials() {

    const [content, setcontent] = useState('You can choose them without  giving a second thought,I was pretty happy with the bridal makeover by Neha,it was very natural and perfect with the outfit.Also all the makeup products used were good ,and as per the choice.All the brides out there looking for a makeover can choose them without any doubt.');


    const handleCard1 = () => {
        setcontent("You can choose them without  giving a second thought,I was pretty happy with the bridal makeover by Neha,it was very natural and perfect with the outfit.Also all the makeup products used were good ,and as per the choice.All the brides out there looking for a makeover can choose them without any doubt.");
    }

    const handleCard2 = () => {
        setcontent("Neha is wonderful. The best part is she listens to you and understands that how you want to look. I wanted an absolutely natural make up, and she made me look so pretty. She will be my go to person for any make up now.  Thank you so much for all your help, you are one seriously talented woman!");
    }

    const handleCard3 = () => {
        setcontent("While looking for makeup artist in Instagram i came across Nehamakeovers page .. And i was so amazed by her work so i dm’ed her ..and with one meeting i was completely sure that she’ll be my MUA for my wedding ..and i had no doubt on how i’ll look because of her 🌺 she makes sure everything from start to finish was perfect. She is very dedicated to what she does. I would give her a 100 stars ⭐️.. her work is outstanding..Thank you Neha .... for making my special day even more special.. and for making me look and feel pretty on my wedding function.. you are professional and absolutely amazing at doing make up.💕. All evening I received compliments from everyone.. I just love you NEHA..you’re such a sweet humble person.. I would only ever recommend you for any bride because you really have that magic touch.. I wish you all the success in your career. God bless you, loads of love 💕 and thank you..☺️☺️ ");
    }

    const handleCard4 = () => {
        setcontent("Thank you Neha for giving me such a lovely look. I absolutely loved it. You are a true professional and perfectionist in your work. You took care of every minute detail and made sure that I looked beautiful in every way. Thanks for the wonderful contouring and accentuating my features!! I have got makeup done from many different artists before, but no one has been able to give me a makeover, like you did. I would highly recommend Neha to everyone !!");
    }

    return (
        <div id="_TestimonialsSection" className="Testimonials">

            <div className="TestimonialsHeader">
                <h6>WHAT THEY SAY ABOUT US</h6>
                <h2>TESTIMONIALS</h2>
                <div className="TestimonialsHeaderBar"></div>
            </div>

            <div className="TestimonialsCards">
                <div className="TestimonialsCard">
                    <div onMouseOver={handleCard1} className="Card">
                        <div className="CardImg">
                            <img src="./images/testimonials/one.jpg" />
                        </div>
                        <div className="CardText">
                            <h2>Ekta Bisht</h2>
                            <p>CLIENT</p>
                        </div>
                    </div>

                    <div onMouseOver={handleCard2} style={{ backgroundColor: '#FFFED6' }} className="Card">
                        <div className="CardImg">
                            <img src="./images/testimonials/two.jpeg" />
                        </div>
                        <div className="CardText">
                            <h2>Neha Uniyal</h2>
                            <p>CLIENT</p>
                        </div>
                    </div>

                    <div onMouseOver={handleCard3} style={{ backgroundColor: '#D7ECD9' }} className="Card">
                        <div className="CardImg">
                            <img src="./images/testimonials/four.JPG" />
                        </div>
                        <div className="CardText">
                            <h2>Kiran Naudiyal</h2>
                            <p>CLIENT</p>
                        </div>
                    </div>

                    <div onMouseOver={handleCard4} style={{ backgroundColor: '#F5D5CB' }} className="Card">
                        <div className="CardImg">
                            <img src="./images/testimonials/three.JPG" />
                        </div>
                        <div className="CardText">
                            <h2>Richa Singla</h2>
                            <p>CLIENT</p>
                        </div>
                    </div>


                </div>
                <div className="TestimonialsCardText">
                    <p><ImQuotesLeft size="5.2rem" color="#FFEFDB" /></p>
                    <div className="TestimonialsAllText">
                        {content}
                    </div>
                </div>
            </div>

        </div>
    )
}

