import React, { useState, useEffect } from 'react';
import './Header.css';
import Nav from '../NavBar/NavBar';
import { AnimatePresence, motion } from "framer-motion";
import Video from './Video';
import { MdApps } from "react-icons/md";
import { FaPlay } from "react-icons/fa";
import { MdVerticalAlignTop } from "react-icons/md";
import { MdClose } from "react-icons/md";


const headerImages = [
    'header1.jpg',
    'header2.jpg',
    'header3.jpg',
    'header2.jpg',
    'header1.jpg',
    'header3.jpg',
    'header2.jpg',
    'header3.jpg',
    'header1.jpg'
];
const headerImagesMobile = [
    'm_header1.jpg',
    'm_header2.jpg',
    'm_header3.jpg',
    'm_header4.jpg',
    'm_header1.jpg',
    'm_header2.jpg',
    'm_header3.jpg',
    'm_header4.jpg',
    'm_header1.jpg',
    'm_header2.jpg',
    'm_header3.jpg',
    'm_header4.jpg',
    'm_header1.jpg',
    'm_header2.jpg',
    'm_header3.jpg',
    'm_header4.jpg'
]

export default function Header() {
    const [showNav, setShowNav] = useState(false);
    const [mainImages, setmainImages] = useState([]);
    const [imgIndex, setimgIndex] = useState();
    const [showVideo, setshowVideo] = useState(false);
    const [showScroll, setShowScroll] = useState(false);

    const NavView = () => {
        if (showNav) {
            setShowNav(false);
        } else {
            setShowNav(true);
        }
    }

    const handleVideo = () => {
        showVideo ? setshowVideo(false) : setshowVideo(true);
    }

    const setInitials = () => {
        const width = window.innerWidth;
        if (width > 940) {
            setmainImages(headerImages);
            setimgIndex(Math.floor(Math.random() * (headerImages.length - 1) + 0));
        } else {
            setmainImages(headerImagesMobile);
            setimgIndex(Math.floor(Math.random() * (headerImagesMobile.length - 1) + 0));
        }

    }

    useEffect(() => {
        setInitials();
        return () => {
            setInitials();
        }
    }, []);

    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setShowNav(false);
            setShowScroll(true);
        }
        else if (window.scrollY <= 80) {
            setShowNav(false);
            setShowScroll(false);
        }
    };

    window.addEventListener('scroll', changeNavbarColor);


    return (
        <div className="Header" style={{ backgroundImage: `url(/images/${mainImages[imgIndex]})` }}>

            <div className="HeaderScreen">

                {
                    showVideo && (
                        <Video handleVideo={handleVideo} />
                    )
                }

                <AnimatePresence>
                    {
                        showNav && (<Nav />)
                    }
                </AnimatePresence>

                <div className="HeaderContainer">


                    <Navigation showNav={showNav} handleVideo={handleVideo} NavView={NavView} />




                    {/*Header Section*/}
                    <AnimatePresence>
                        {
                            showScroll && (
                                <motion.a
                                    className="ScrollTop"
                                    href="#"
                                    exit={{ opacity: 0 }}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ ease: "easeOut", duration: 0.5 }}
                                ><MdVerticalAlignTop size="2.25rem" color="#dd6e8b" /></motion.a>
                            )
                        }
                    </AnimatePresence>

                    <div className="HeaderContent">
                        <div className="HeaderContentBar" />
                        <div className="HeaderContentTitle">
                            MAKEUP ARTIST
                        </div>
                        <div className="HeaderContentName">
                            Neha Saini Gupta
                        </div>
                        <a href="#_AboutSection">
                        <div className="HeaderContentBtn">
                            About Me
                        </div>
                        </a>
                    </div>
                    <div className="scrolldown">
                        <div className="downbox">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Navigation = ({ showNav, handleVideo, NavView }) => {
    return (
        <div className="HeaderTop">
            <div className="HeaderTopLogo">
                <img
                    src="/images/logo.png"
                    alt="Picture of the author"
                />
            </div>
            <div>
                <div onClick={handleVideo} className="VideoButton">
                    <div className="InnerButton">
                        <span><FaPlay size="1.5rem" color="#EA4949" /></span>
                    </div>
                </div>
                <span onClick={NavView}>
                    {
                        showNav && (
                            <motion.span
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ ease: "easeOut", duration: 0.5 }}
                            >
                                <MdClose size="4rem" color="#fff" />
                            </motion.span>
                        )
                    }
                    {
                        !showNav && (
                            <motion.span
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ ease: "easeOut", duration: 0.5 }}
                            >
                                <MdApps size="4rem" color="#fff" />
                            </motion.span>
                            
                        )
                    }
                </span>

            </div>
        </div>
    )
}

