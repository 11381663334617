import React, {useEffect} from 'react';
import Header from '../Header/Header';
import About from '../About/About';
import Portfolio from '../Portfolio/Portfolio';
import Testimonials from '../Testimonials/Testimonials';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';

export default function HomePage() {
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("Scroll");
    }, []);
    return (
        <div>
            <Header />
            <About />
            <Portfolio />
            <Testimonials />
            <Contact />
            <Footer />
        </div>
    )
}