import React from 'react';
import './Portfolio.css';
import { Link } from 'react-router-dom';
import { HiArrowNarrowRight } from 'react-icons/hi';

export default function PortfolioBox({ title, image }) {


    return (
        <div className="PortfolioBoxMain">
            <style jsx>
                {`
                    .PortfolioBox {
                        background-size: cover;
                        width: 46.2rem;
                        height: 28.8rem;
                    }

                    @media only screen and (max-width: 1140px) {
                        .PortfolioBox {
                            width: 40.2rem;
                            height: 22.8rem;
                        }
                    }

                    @media only screen and (max-width: 900px) {
                        .PortfolioBox {
                            width: 100%;
                            height: 29.8rem;
                        }
                    }

                    @media only screen and (max-width: 720px) {
                        .PortfolioBox {
                            width: 100%;
                            height: 20.8rem;
                        }
                    }
                    @media only screen and (max-width: 640px) {
                        .PortfolioBox {
                            width: 100%;
                            height: 24.8rem;
                        }
                    }
                    @media only screen and (max-width: 480px) {
                        .PortfolioBox {
                            width: 100%;
                            height: 20.8rem;
                        }
                    }

                    @media only screen and (max-width: 380px) {
                        .PortfolioBox {
                            width: 100%;
                            height: 15.8rem;
                        }
                    }
                    
                `}
            </style>
            <Link to={{
                pathname: `/${title}`
            }}>
                <div className="PortfolioBox">
                    <img src={`/images/${image}`} alt="Image" />
                    <div id="_ImageTitle">{title}</div>
                </div>
            </Link>


        </div>
    )
}
