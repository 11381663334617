import React from 'react';
import './Portfolio.css';
import PortfolioBox from './PortfolioBox';


export default function Portfolio() {


    return (
        <div id="_PortfolioSection" className="Portfolio">

            <div className="PortfolioHeader">
                <h6>WHAT WE DO</h6>
                <h2>OUR PORTFOLIO</h2>
                <div className="PortfolioHeaderBar"></div>
            </div>
            <div className="PortfolioContent">
                <PortfolioBox title="Pre-Bridal Makeup" image="bridal.JPEG" />
                <PortfolioBox title="Bridal Makeup" image="bridal.JPEG" />
            </div>
            <div className="PortfolioContentBottom">
                <PortfolioBox title="Mehendi" image="portfolio1.jpg" />
                <PortfolioBox title="Party Makeup" image="mehndi.jpg" />         
            </div>
            <div className="PortfolioContentBottom">
                <PortfolioBox title="Senior Makeup Artist" image="partymakeup.jpg" />
                <PortfolioBox title="Hairstyling" image="portfolio1.jpg" />
            </div>
            <div className="PortfolioContentBottom">
                <PortfolioBox title="Fashion &amp; Beauty" image="hairstyle.jpg" />
            </div>
            
        </div>
    )
}
