import React, { useState } from 'react';
import './Contact.css';
import emailjs from 'emailjs-com'

export default function Form() {

    const [msgStatus, setMsgStatus] = useState(404);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_k9pr0qh', 'template_0v6989h', e.target, 'user_OXFeJFPcOjSmB8RjFj22p')
            .then((result) => {
                setMsgStatus(200);
            }, (error) => {
                setMsgStatus(500);
            });

        e.target.reset();
    }

    const handleChange = () => {
        setMsgStatus(404);
    }


    return (
        <form onSubmit={sendEmail} className="Form">
            <input onChange={handleChange} type="email" placeholder="Email" name="email" required />
            <input onChange={handleChange} type="text" placeholder="Message" name="msg" required />
            {
                msgStatus === 200 && (
                    <>
                        <div className="MsgSucess">Message Sent !!</div>
                        <div className="SucessContext">Hi! We have recieved you email and will contact you shortly.</div>
                    </>
                )

            }

            {
                msgStatus !== 200 && (
                    <button>Send</button>
                )
            }

            {
                msgStatus === 500 && (
                    <div className="SucessContext">Opps!! Looks like someting went wrong. Please re-send the email.</div>
                )
            }
        </form>
    )
}